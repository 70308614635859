import { FiGithub, FiLinkedin } from 'react-icons/fi';
import { AiOutlineMail } from 'react-icons/ai';
import { BiHomeAlt } from 'react-icons/bi';

const SideBar = () => (
    <div className='fixed top-0 left-0 h-screen w-16 m-0 flex flex-col'>
        <a href="/">
            <SideBarIcon tooltip='Home' icon={<BiHomeAlt size="36" />} />
        </a>
        <div className='m-auto'>
            <a href="https://github.com/jflorchi">
                <SideBarIcon tooltip='GitHub' icon={<FiGithub size="36" />} />
            </a>
            <a href="https://www.linkedin.com/in/jordan-florchinger">
                <SideBarIcon tooltip='LinkedIn' icon={<FiLinkedin size="36" />} />
            </a>
            <a href="mailto:jordan.florchinger@alumni.uleth.ca">
                <SideBarIcon tooltip='Contact' icon={<AiOutlineMail size="36" />} />
            </a>    
        </div>
    </div>
);

const SideBarIcon = ({ icon, tooltip = "tooltip" }) => (
    <div className='sidebar-icon group'>
        {icon}
        <span className='sidebar-tooltip group-hover:scale-100'>
            {tooltip}
        </span>
    </div>
);

export default SideBar;
import SideBar from './sidebar/SideBar';
import HeroSection from './hero/Hero';
import ProjectsContainer from './projects/Projects';
import ProjectRenderer from './projects/ProjectRenderer';

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';


const LandingPage = () => (
  <div className="h-screen font-Raleway">
    <SideBar />
    <HeroSection />
  </div>
);
 
const ErrorPage = () => (
  <div className='flex font-Raleway h-screen w-screen'>
    <div className='m-auto animate-bounce'>
      <h1 className='text-transparent font-extrabold text-8xl bg-clip-text bg-gradient-to-bl from-green-300 to-cyan-300 text-center align-middle'>oops</h1>
      <h1 className='text-transparent font-extrabold text-4xl bg-clip-text bg-gradient-to-bl from-green-300 to-cyan-300 text-center align-middle pb-2'>page not found</h1>
    </div>
  </div>
);

const ProjectsPage = () => (
  <div className='font-Raleway min-h-fit w-fit'>
    <ProjectsContainer />
  </div>
);

const ProjectPage = () => {
  const params = useParams();
  const path = params.path;

  return (
    <div>
      <ProjectRenderer path={path} />
      <br/>
      <br/>
    </div>
  );
}


const App = () => (
  <div className='bg-gray-900 min-h-screen min-w-[100%]'>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:path" element={<ProjectPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  </div>
);

export default App;

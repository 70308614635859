
const ThreeDots = () => (
    <div className='flex flex-row animate-fade-in-up'>
        <div className="rounded-full bg-green-300 w-12 h-12 ml-2 mr-2 animate-bounce"></div>
        <div className="rounded-full bg-green-300 w-12 h-12 ml-2 mr-2 animate-bounce animation-delay-200"></div>
        <div className="rounded-full bg-green-300 w-12 h-12 ml-2 mr-2 animate-bounce animation-delay-400"></div>
    </div>
);


export default ThreeDots;

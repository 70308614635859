import { useEffect, useState } from 'react';
import SideBar from "../sidebar/SideBar";

import firebaseApp from '../Firebase';
import { collection, getFirestore, query, getDocs } from "firebase/firestore";

const ProjectSummaries = ({ path, title, summary, startDate, endDate }) => {
    const url = "/projects/" + path;

    const startDateObj = startDate.toDate();
    const endDateObj = endDate.toDate();
    startDateObj.setMonth(startDateObj.getMonth());
    endDateObj.setMonth(endDateObj.getMonth());
    const startDateString = startDateObj.toLocaleString('en-CA', { month: 'short' }) + " " + startDateObj.getFullYear();
    const endDateString = endDateObj.toLocaleString('en-CA', { month: 'short' }) + " " + endDateObj.getFullYear();

    return (
        <a href={url}>
            <div className='text-white p-2 m-2'>
                <h1 className='font-extrabold text-3xl text-left sm:pl-0 md:pl-[10%] lg:pl-[20%]'>{ title }</h1>
                <h1 className='font-extralight text-xs text-left sm:pl-0 md:pl-[10%] lg:pl-[20%]'>{ startDateString } - { endDateString }</h1>
                <p className='text-left sm:pl-0 md:pl-[10%] lg:pl-[20%]'>{ summary }</p>
            </div>
        </a>
    );
};

const ProjectSummary = () => {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        const ref = getFirestore(firebaseApp);
        const q = query(collection(ref, "projects"));
        getDocs(q).then((querySnapshot) => {
            const projects = [];
            querySnapshot.forEach((doc) => {
                projects.push(doc.data());
            });
            setProjects(projects);
        });
    }, []);
    if (projects.length !== 0) {
        var featured = [];
        var normal = [];
        projects.forEach(element => {
            if (element.featured) {
                featured.push(element);
            } else {
                normal.push(element);
            }
        });
        return (
            <>
                <SideBar />
                <h1 className='mt-2 pb-2 text-transparent font-extrabold text-6xl bg-clip-text bg-gradient-to-bl from-green-300 to-cyan-300 text-center animate-fade-in-up'>projects</h1>
                <div className='pl-16 sm:pr-0 md:pr-[20%] mt-auto mb-auto animate-fade-in-up-long'>
                    { featured.map((project) => (
                        <ProjectSummaries
                            key={project.id}
                            path={project.path}
                            title={project.title} 
                            summary={project.summary} 
                            startDate={project.start_date} 
                            endDate={project.end_date} /> 
                    )).concat(normal.map((project) => (
                        <ProjectSummaries
                            key={project.id}
                            path={project.path}
                            title={project.title} 
                            summary={project.summary} 
                            startDate={project.start_date} 
                            endDate={project.end_date} /> 
                    )))
                }
                </div>
            </>
        );
    } else {
        return (
            <div className="bg-gray-900 h-full w-full text-white">
                <SideBar />
            </div>
        );
    }
};

const ProjectsContainer = () => (
    <div className='flex flex-col min-h-fit'>
        <ProjectSummary />
    </div>
    
);

export default ProjectsContainer;

import { Link } from 'react-router-dom';

const HeroSection = () => (
    <div className='hero-section flex flex-col place-items-center items-center justify-center h-screen'>
        <Title />
        <Description />
        <Blob />
    </div>
);

const Button = () => (
    <div className='pt-4'>
        <Link to="/projects"><button className='p-2 border-green-300 border-2 rounded-md'>view my projects</button></Link>
    </div>
);

const Title = () => (
    <div className='flex-shrink mr-auto pl-[20%] animate-fade-in-up'>
        <h1 className='p-0 m-0 text-white text-left font-light sm:text-lg md:text-1xl lg:text-2xl'>hello, my name is</h1>
        <h1 className='p-0 m-0 text-transparent font-extrabold text-2xl md:text-4xl lg:text-6xl bg-clip-text bg-gradient-to-bl from-green-300 to-cyan-300'>JORDAN FLORCHINGER</h1>
        <h1 className='p-0 m-0 text-white text-right font-light sm:text-lg md:text-1xl lg:text-2xl'>and I like to create stuff</h1>
    </div>
);

const Description = () => (
    <div className='flex flex-col text-white mr-auto mt-4 pl-[20%] max-w-4xl animate-fade-in-up'>
        <p className='sm:text-sm md:text-md lg:text-lg'>I am a jack of all trades when it comes to technology. I’ve done everything from software development and system administration to embedded hardware and software.</p>
        <Button />
    </div>
);

const Blob = () => (
    <div className='pointer-events-none absolute right-1/4 w-72 h-72 animate-fade-in-up'>
        <div className='absolute rounded-full bg-green-400 right-1/4 w-72 h-72 filter blur-3xl opacity-[0.07] animate-blob-one'></div>
        <div className='absolute rounded-full bg-cyan-400 right-1/4 w-72 h-72 filter blur-3xl opacity-[0.07] animate-blob-two'></div>
    </div>
);

export default HeroSection;

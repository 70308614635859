
import { useEffect, useState } from "react";
import { marked } from "marked";

import DOMPurify from "dompurify";
import SideBar from "../sidebar/SideBar";
import ThreeDots from "../animations/Animations";

import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import firebaseApp from '../Firebase';
import { collection, getFirestore, query, getDocs, where } from "firebase/firestore";

const ProjectDate = ({ startDate, endDate, link }) => {
    const startDateObj = startDate.toDate();
    const endDateObj = endDate.toDate();
    startDateObj.setMonth(startDateObj.getMonth());
    endDateObj.setMonth(endDateObj.getMonth());
    const startDateString = startDateObj.toLocaleString('en-CA', { month: 'short' }) + " " + startDateObj.getFullYear();
    const endDateString = endDateObj.toLocaleString('en-CA', { month: 'short' }) + " " + endDateObj.getFullYear();
    return (
        <div className='flex flex-row text-white pt-2 sm:pl-0 sm:pr-0 md:pl-[20%] md:pr-[20%] animate-fade-in-up'>
            <h1 className='flex font-extralight text-xs text-left m-auto ml-0'>{ startDateString } - { endDateString }</h1>
            <div className="flex justify-end mr-2 ">
                <Icon icon={<BsFillArrowUpRightCircleFill size="24" />} link={link} />
            </div>
        </div>
    );
};

const Icon = ({ icon, link }) => (
    <div className="icon">
        <a href={link}>
            {icon}
        </a>
    </div>
);


const ProjectRenderer = ({ path }) => {
    const [project, setProject] = useState();
    useEffect(() => {
        const ref = getFirestore(firebaseApp);
        const q = query(collection(ref, "projects"), where("path", "==", path));
        getDocs(q).then((querySnapshot) => {
            setProject(querySnapshot.docs[0].data());
        });
    }, [path]);
    if (project) {
        marked.setOptions({
            stylesheet: "./ProjectRenderer.module.css"
        });
        return(
            <div className="h-full text-white">
                <SideBar />
                <div className="pl-16 flex flex-col h-full">
                    <h1 className="animate-fade-in-up pr-0 md:pl-[20%] md:pr-[20%] pb-1 pt-8 m-0 text-transparent font-extrabold text-2xl md:text-2xl lg:text-4xl bg-clip-text bg-gradient-to-bl from-green-300 to-cyan-300">{ project.title }</h1>
                    <ProjectDate startDate={ project.start_date } endDate={ project.end_date } link={ project.link } />
                    <div className="pt-4 pr-[5%] md:pl-[20%] md:pr-[20%] animate-fade-in-up-long" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(project.content))}} />
                </div>
            </div>
        )
    }
    return (
        <div className="bg-gray-900 h-full w-full text-white">
            <SideBar />
            <div className="grid h-screen place-items-center opacity-50">
                <ThreeDots />
            </div>
        </div>
    );
};


export default ProjectRenderer;

import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyBmF9vgPRzi9894mhOfCy5OIrlLji7tUn8",
  authDomain: "jflorchi-83a64.firebaseapp.com",
  projectId: "jflorchi-83a64",
  storageBucket: "jflorchi-83a64.appspot.com",
  messagingSenderId: "285050103111",
  appId: "1:285050103111:web:a4a5ab2dfabdcec5985862",
  measurementId: "G-3C38QH8FZ9"
};


const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default firebaseApp;